import React, { useState } from "react";
import { sliderData } from "./data";
import Slider from "react-slick";
import LazyLoad from "react-lazyload";
import cross from '../../../images/common/cross-dark.png';
import "./styles.scss";

function SamplePrevArrow(props) {
  const { className, onClick } = props;

  return (
    <div className={className} onClick={onClick} aria-hidden="true">
      <img
        className="alumni-nextDKHero"
        src="https://res.cloudinary.com/judiscloud/image/upload/v1583679105/Subtract_1.svg"
        alt="prev"
      />
    </div>
  );
}

function SampleNextArrow(props) {
  const { className, onClick } = props;

  return (
    <div className={className} onClick={onClick} aria-hidden="true">
      <img
        className="alumni-prevDKHero"
        src="https://res.cloudinary.com/judiscloud/image/upload/v1583679105/Subtract_1.svg"
        alt="next"
      />
    </div>
  );
}

function YoutubeSlider() {
  const [videoData, setVideoData] = useState(null);
  const [showVideo, setShowVideo] = useState(false);

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplaySpeed: 3000,
    autoplay: false,
    speed: 500,
    dots: false,
    fadeIn: false,
    pauseOnHover: true,
    slidesToScroll: 1,
    arrows: true,
  };
  const setVideoHandler = (student) => {
    setVideoData(student);
    setShowVideo(true);
  };

  const hideModalHandler = () => {
    setShowVideo(false);
    setVideoData(null);
  };

  return (
    <div className="alumni-youtube-testimonial-container">
      <h2 className="alumni-experience-heading">
        meet the marketing and
        <br /> Sales Leaders
      </h2>
      <div className="alumni-heroVideoWrap">
        <div className="alumni-hero-slides">
          <Slider {...settings}>
            {sliderData.map((item) => {
              let postAlt = "Kraftshala Reviews";

              if (item.program === "Marketing Launchpad Program") {
                postAlt = " Kraftshala Digital Marketing Course Review";
              } else if (item.program === "B School Program") {
                postAlt = " Kraftshala Bschool Course Review";
              } else if (item.program === "Professional Brand Building") {
                postAlt =
                  " Kraftshala Professional Brand Management Course Review";
              }

              return (
                <div className="alumni-hero-slide" key={item.id}>
                  <div className="alumni-hero-slide-content">
                    <p className="alumni-hero-slide-content-heading">
                      {item.title}
                    </p>
                    <p className="alumni-hero-slide-content-program">
                      {item.program}
                    </p>
                    <div className="alumni-hero-slide-divider" />
                    <p className="alumni-hero-slide-content-desc">
                      {item.description}
                    </p>
                  </div>
                  <div className="alumni-hero-slide-video">
                    <LazyLoad>
                      {/* <iframe
                      title={item.title}
                      width="100%"
                      height="100%"
                      src={item.link}
                      frameBorder={0}
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    /> */}
                      <img
                        className="testimonial-yt-thumb"
                        src={item.youtubeImg}
                        alt={item.alt + postAlt}
                        title={item.alt + postAlt}
                        onClick={() => setVideoHandler(item)}
                        aria-hidden="true"
                      />
                    </LazyLoad>
                  </div>
                </div>
              );
            })}
          </Slider>
          {showVideo ? (
            <React.Fragment>
              <div
                className="spec-mask spec-active"
                onClick={hideModalHandler}
                aria-hidden="true"
              ></div>
              <div className="spec-modal">
                <img
                  src= {cross}
                  alt="cross icon"
                  className="spec-modal-cross"
                  onClick={hideModalHandler}
                  aria-hidden="true"
                />
                <iframe
                  width="100%"
                  height="100%"
                  src={videoData.link + "?autoplay=1"}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="youtube-player"
                ></iframe>
              </div>
            </React.Fragment>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default YoutubeSlider;
